import { BaseEntity } from './BaseEntity';

export enum AdvertisementPosition {
  HOME = 'home',
  HOME_GUEST = 'home_guest',
  ACCOUNT_SETTING = 'account_setting',
  VIDEO = 'video',
}

export interface Advertisement extends BaseEntity {
  name: string
  position: AdvertisementPosition
  image: string
  imageLink: string
  link: string
  mobileImage: string
  mobileImageLink: string
  show: number
}

export type PartialAdvertisement = Partial<Advertisement>;
